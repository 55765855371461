/* Estilos para a página de configuração de Crawl */
.crawl-config {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  padding-top: 70px;
}

.main-content {
  margin: 0 auto;
  color: white;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
}

.crawl-config-header {
  margin-bottom: 2rem;
}

.title-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #b3b3b3;
  font-size: 0.9rem;
}

.form-container {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
}

.project-info {
  background: #111111;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #333;
}

.info-item:last-child {
  border-bottom: none;
}

.info-label {
  color: #b3b3b3;
  font-size: 0.9rem;
}

.project-type {
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.project-type.youtube {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4444;
}

.project-type.site {
  background: rgba(33, 150, 243, 0.1);
  color: #2196f3;
}

.project-url {
  color: #6200ee;
  font-size: 0.9rem;
  word-break: break-all;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-group input {
  padding: 0.8rem 1rem;
  font-size: 1rem;
  border: 1px solid #333;
  border-radius: 6px;
  background: #1a1a1a;
  color: white;
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: #6200ee;
  outline: none;
  background: rgba(98, 0, 238, 0.1);
}

.input-hint {
  font-size: 0.8rem;
  color: #b3b3b3;
  margin-top: 0.25rem;
}

.error-message {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
  padding: 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
}

.form-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.form-actions button {
  flex: 1;
  padding: 0.8rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary-btn {
  background: #6200ee;
  color: white;
  border: none;
}

.secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid #333;
}

.primary-btn:hover:not(:disabled) {
  background: #7722ff;
}

.secondary-btn:hover {
  border-color: #6200ee;
}

.primary-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  color: #b3b3b3;
}

/* Responsividade */
@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }

  .form-container {
    padding: 1.5rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    width: 100%;
  }
}

.limit-info {
  font-size: 0.9em;
  color: #888;
  margin-left: 8px;
}

.plan-usage {
  color: #6200ee;
  font-weight: 500;
}

.form-group input[type="number"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input[type="number"]:focus {
  border-color: #6200ee;
  outline: none;
}

.form-group input[type="number"]:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.info-item .plan-usage {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
