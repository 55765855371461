.user-header {
  background: rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.menu-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1100;
}

.menu-icon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;
  position: relative;
  z-index: 1001;
}

.menu-icon span {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.menu-icon.active span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.menu-icon.active span:nth-child(2) {
  opacity: 0;
}

.menu-icon.active span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

.user-header-title {
  margin: 0;
}

.user-header-title a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
}

.user-header-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-greeting {
  color: #fff;
  font-size: 0.875rem;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #6200ee;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  transition: left 0.3s ease;
  padding: 5rem 0 2rem;
  overflow-y: auto;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-section {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  text-transform: uppercase;
  padding: 1rem 2rem 0.5rem;
}

.sidebar-list li a,
.sidebar-list li button {
  display: block;
  padding: 0.75rem 2rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sidebar-list li a:hover,
.sidebar-list li button:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #6200ee;
}

.sidebar-list hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 1rem 0;
}

.logout-button {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 2rem;
}

.social-links {
  display: flex;
  gap: 1rem;
  padding: 0.75rem 2rem;
}

.social-links a {
  padding: 0.5rem !important;
  font-size: 0.875rem;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.sidebar-overlay.open {
  display: block;
}

.support-section {
  margin-left: auto;
  padding: 0 1rem;
}

.support-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.support-button.email {
  background-color: transparent;
  border: 1px solid #6200ee;
  color: #6200ee;
}

.support-button.email:hover {
  background-color: rgba(98, 0, 238, 0.1);
}

.support-button.chat {
  background-color: #6200ee;
  border: none;
  color: white;
}

.support-button.chat:hover {
  background-color: #7722ff;
}

.support-button.dedicated {
  background-color: #2e7d32;
  border: none;
  color: white;
}

.support-button.dedicated:hover {
  background-color: #3d8b40;
}

.support-button svg {
  font-size: 1.1rem;
}