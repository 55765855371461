.signup-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  padding: 1rem;
}

.signup-box {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.signup-header {
  text-align: center;
  margin-bottom: 2rem;
}

.signup-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.signup-header h1 {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 500;
}

.signup-form {
  width: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0);
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  position: relative;
}

.signup-form-input {
  width: 91%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.signup-form-input:focus {
  outline: none;
  border-color: #6200ee;
  background: rgba(255, 255, 255, 0.08);
}

.signup-button {
  width: 100%;
  padding: 0.75rem;
  background: #6200ee;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.signup-button:hover {
  background: #7722ff;
}

.signup-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: rgba(255, 255, 255, 0.5);
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.divider span {
  padding: 0 1rem;
  font-size: 0.875rem;
}

.google-button {
  width: 100%;
  padding: 0.75rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.google-button:hover {
  background: rgba(255, 255, 255, 0.05);
}

.error-message {
  margin-top: 1rem;
  color: #ff4444;
  text-align: center;
  font-size: 0.875rem;
}

.signup-footer {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

.signup-footer a {
  color: #6200ee;
  text-decoration: none;
}

.signup-footer a:hover {
  text-decoration: underline;
}
