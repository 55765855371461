/* Layout Principal */
.projects {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  padding-top: 70px;
}

.main-content {
  margin: 0 auto;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  width: 100%;
}

/* Header da página */
.projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #6200ee;
}

.project-count {
  color: #b3b3b3;
  font-size: 0.9rem;
}

/* Seção de filtros */
.filters-section {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  background: #1a1a1a;
  padding: 1rem;
  border-radius: 10px;
}

.search-box {
  flex: 1;
}

.search-box input,
.filter-box select {
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border: 1px solid #333;
  border-radius: 6px;
  background: #1a1a1a;
  color: white;
  transition: all 0.3s ease;
}

.search-box input:focus,
.filter-box select:focus {
  border-color: #6200ee;
  outline: none;
}

/* Grid de projetos */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* Cards de projeto */
.project-card {
  background: #1a1a1a;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
  position: relative;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
}

.project-type {
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.project-type.youtube {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4444;
}

.project-type.site {
  background: rgba(98, 0, 238, 0.1);
  color: #6200ee;
}

.project-info {
  padding: 1.5rem;
}

.project-info h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: white;
}

.project-date {
  color: #b3b3b3;
  font-size: 0.9rem;
}

.project-actions {
  padding: 1rem;
  display: flex;
  gap: 0.8rem;
  border-top: 1px solid #333;
}

.action-btn {
  flex: 1;
  padding: 0.8rem;
  text-align: center;
  border-radius: 6px;
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 0.3s ease;
}

.runs-btn {
  background: #1a1a1a;
  color: white;
}

.dashboard-btn, .download-btn {
  background: #6200ee;
  color: white;
}

.runs-btn:hover {
  background: #333;
}

.dashboard-btn:hover, .download-btn:hover {
  background: #7722ff;
}

/* Botão de criar projeto */
.create-project-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #6200ee;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.create-project-btn:hover {
  background: #7722ff;
  transform: translateY(-2px);
}

.create-project-btn span {
  font-size: 1.2rem;
}

/* Estado vazio */
.no-projects {
  grid-column: 1 / -1;
  text-align: center;
  padding: 3rem;
  background: #252525;
  border-radius: 12px;
}

.no-projects p {
  margin-bottom: 1.5rem;
  color: #b3b3b3;
}

/* Paginação */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.page-btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  background: #252525;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.page-btn:hover:not(:disabled) {
  background: #333;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  color: #b3b3b3;
}

/* Responsividade */
@media (max-width: 768px) {
  .filters-section {
    flex-direction: column;
  }
  
  .projects-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}
