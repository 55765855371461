.forgot-password-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  padding: 1rem;
}

.forgot-password-box {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.forgot-password-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.forgot-password-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.forgot-password-header h1 {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 500;
}

.instructions {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.forgot-password-form {
  width: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  position: relative;
}

.forgot-form-input {
  width: 91%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: #6200ee;
  background: rgba(255, 255, 255, 0.08);
}

.recovery-button {
  width: 100%;
  padding: 0.75rem;
  background: #6200ee;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.recovery-button:hover {
  background: #7722ff;
}

.recovery-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.status-message {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
  text-align: center;
  font-size: 0.875rem;
}

.status-message.error {
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.2);
  color: #ff4444;
}

.status-message.success {
  background: rgba(98, 0, 238, 0.1);
  border: 1px solid rgba(98, 0, 238, 0.2);
  color: #6200ee;
}

.forgot-password-footer {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
}

.forgot-password-footer a {
  color: #6200ee;
  text-decoration: none;
}

.forgot-password-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .forgot-password-container {
    padding: 1.5rem;
  }

  .forgot-password-logo img {
    height: 40px;
  }

  .forgot-password-logo h1 {
    font-size: 1.5rem;
  }
} 