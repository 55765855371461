.complete-signup-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  padding: 1rem;
}

.complete-signup-box {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.complete-signup-header {
  text-align: center;
  margin-bottom: 2rem;
}

.complete-signup-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.complete-signup-header h1 {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 500;
}

.complete-signup-form {
  width: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  position: relative;
}

.complete-signup-input {
  width: 91%;
  padding: 0.75rem 1rem;
  padding-left: 2.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.complete-signup-input:focus {
  outline: none;
  border-color: #6200ee;
  background: rgba(255, 255, 255, 0.08);
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
}

.complete-signup-button {
  width: 100%;
  padding: 0.75rem;
  background: #490e9b;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.complete-signup-button:hover:not(:disabled) {
  background: #7722ff;
}

.complete-signup-button:disabled {
  background: rgba(73, 14, 155, 0.5);
  cursor: not-allowed;
}

.password-requirements {
  margin-top: 0.5rem;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  font-size: 0.875rem;
}

.password-requirements p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.password-requirements ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.password-requirements li {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  padding-left: 1.2rem;
  position: relative;
}

.password-requirements li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #6200ee;
}

.error-message {
  margin-top: 1rem;
  color: #ff4444;
  text-align: center;
  font-size: 0.875rem;
}

.error-message span {
  display: block;
  margin-bottom: 0.3rem;
}

@media (max-width: 480px) {
  .complete-signup-box {
    padding: 1.5rem;
  }

  .complete-signup-logo {
    width: 50px;
    height: 50px;
  }

  .complete-signup-header h1 {
    font-size: 1.1rem;
  }
} 