.auth-callback-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.auth-callback-box {
  text-align: center;
  color: #fff;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1a1a;
}

.auth-loading {
  text-align: center;
  color: #ffffff;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  border: 4px solid #333;
  border-radius: 50%;
  border-top-color: #4CAF50;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 