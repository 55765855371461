.contact-section {
  position: relative;
  padding: 8rem 2rem;
  background: #000000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.contact-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-header {
  text-align: center;
  margin-bottom: 4rem;
  width: 100%;
  max-width: 600px;
}

.contact-title {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
}

.contact-subtitle {
  font-size: 1.25rem;
  color: #a0a0a0;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form {
  width: 100%;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.contact-input,
.contact-textarea {
  width: 95%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.contact-input:focus,
.contact-textarea:focus {
  outline: none;
  border-color: #6200ee;
  background: rgba(255, 255, 255, 0.08);
}

.contact-textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-button {
  padding: 1rem;
  background: #6200ee;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-button:hover:not(:disabled) {
  background: #7722ff;
  transform: translateY(-2px);
}

.contact-button.submitting {
  opacity: 0.7;
  cursor: not-allowed;
}

.contact-status {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.contact-status.success {
  background: rgba(111, 207, 151, 0.2);
  border: 1px solid rgba(111, 207, 151, 0.3);
  color: #6FCF97;
}

.contact-status.error {
  background: rgba(235, 87, 87, 0.2);
  border: 1px solid rgba(235, 87, 87, 0.3);
  color: #EB5757;
}

.contact-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.gradient-sphere {
  position: absolute;
  width: 800px;
  height: 800px;
  right: -200px;
  top: -200px;
  background: radial-gradient(
    circle,
    rgba(98, 0, 238, 0.15) 0%,
    rgba(156, 39, 176, 0.1) 40%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 50%;
  filter: blur(80px);
  animation: spherePulse 15s ease-in-out infinite;
}

@media (max-width: 768px) {
  .contact-section {
    padding: 2rem 1rem;
  }
  
  .contact-container,
  .contact-form,
  .contact-header {
    width: 100%;
    max-width: 500px;
  }

  .contact-title {
    font-size: 2.5rem;
  }

  .contact-form {
    padding: 1.5rem;
  }
}

  