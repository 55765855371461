.pricing-section {
  position: relative;
  padding: 8rem 2rem;
  background: #000000;
  overflow: hidden;
}

.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.pricing-header {
  text-align: center;
  margin-bottom: 4rem;
}

.pricing-header h2 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
}

.pricing-header p {
  font-size: 1.25rem;
  color: #a0a0a0;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  position: relative;
}

.pricing-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  position: relative;
  transition: all 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.pricing-card.popular {
  background: rgba(98, 0, 238, 0.1);
  border-color: rgba(98, 0, 238, 0.3);
}

.popular-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #6200ee;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
}

.plan-header {
  text-align: center;
  margin-bottom: 2rem;
}

.plan-header h3 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.plan-description {
  color: #a0a0a0;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

.plan-price {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
}

.plan-price .currency {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}
.plan-price .amount {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}

.plan-price .period {
  color: #a0a0a0;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.plan-features li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #fff;
  margin-bottom: 1rem;
}

.check-icon {
  width: 18px;
  height: 18px;
  fill: #6FCF97;
}

.choose-plan-button {
  width: 100%;
  padding: 1rem;
  background: #6200ee;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.choose-plan-button:hover {
  background: #7722ff;
}

@media (max-width: 1200px) {
  .pricing-grid {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .pricing-section {
    padding: 4rem 1rem;
  }

  .pricing-grid {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin: 0 auto;
  }

  .pricing-header h2 {
    font-size: 2.5rem;
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  position: relative;
  border: 1px solid #6200ee;
}

.modal-content h2 {
  color: #fff;
  margin-bottom: 1rem;
}

.modal-content p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
}

.contact-info {
  background: rgba(98, 0, 238, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.contact-info a {
  color: #6200ee;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.modal-content button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.modal-content .primary-btn {
  background: #6200ee;
  color: white;
  border: none;
}

.modal-content .secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-content .primary-btn:hover {
  background: #7722ff;
}

.modal-content .secondary-btn:hover {
  border-color: rgba(255, 255, 255, 0.4);
}

.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  gap: 1rem;
}

.billing-toggle span {
  font-size: 1rem;
  color: #666;
  transition: color 0.3s ease;
}

.billing-toggle span.active {
  color: #6200ee;
  font-weight: 600;
}

.save-badge {
  background-color: #4CAF50;
  color: white !important;
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #6200ee;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6200ee;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Ajuste para preços anuais */
.price .period {
  font-size: 1rem;
  color: #666;
  margin-left: 0.25rem;
}

  