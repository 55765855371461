/* Página de Criação de Projetos */
.create-project {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  padding-top: 70px;
}

.main-content {
  margin: 0 auto;
  color: white;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
}

.create-project-header {
  margin-bottom: 2rem;
}

.title-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #b3b3b3;
  font-size: 0.9rem;
}

.form-container {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-group input,
.form-group select {
  padding: 0.8rem 1rem;
  font-size: 1rem;
  border: 1px solid #333;
  border-radius: 6px;
  background: #1a1a1a;
  color: white;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #6200ee;
  outline: none;
  background: rgba(98, 0, 238, 0.1);
}

.input-hint {
  font-size: 0.8rem;
  color: #b3b3b3;
  margin-top: 0.25rem;
}

.error-message {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
  padding: 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
}

.form-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.form-actions button {
  flex: 1;
  padding: 0.8rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.primary-btn {
  background: #6200ee;
  color: white;
  border: none;
}

.secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid #333;
}

.primary-btn:hover:not(:disabled) {
  background: #7722ff;
}

.secondary-btn:hover {
  border-color: #6200ee;
}

.primary-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Spinner para loading */
.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }

  .form-container {
    padding: 1.5rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    width: 100%;
  }
}
