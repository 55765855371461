.user-home {
  min-height: 100vh;
  background: #000000;
  color: #fff;
}

.user-home-content {
  padding: 7rem 2rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-section {
  text-align: center;
  margin-bottom: 3rem;
}

.welcome-section h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.welcome-section p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.125rem;
}

.user-home-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.user-home-info-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.user-home-info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.user-home-info-card h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #6200ee;
}

.user-home-info-card p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.user-home-action-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #6200ee;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.user-home-action-button:hover {
  background: #7722ff;
}

@media (max-width: 768px) {
  .user-home-content {
    padding: 6rem 1rem 1rem;
  }

  .welcome-section h1 {
    font-size: 1.5rem;
  }

  .user-home-info-grid {
    grid-template-columns: 1fr;
  }
}