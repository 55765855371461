.payments {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  padding-top: 70px;
}

.main-content {
  margin: 0 auto;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  width: 100%;
}

.payments-header {
  margin-bottom: 2rem;
}

.payments-header h2 {
  font-size: 2rem;
  color: #6200ee;
}

.month-group {
  margin-bottom: 2.5rem;
}

.month-header {
  color: #b3b3b3;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #333;
}

.payments-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-card {
  background: #1a1a1a;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.payment-card:hover {
  transform: translateY(-2px);
}

.payment-info {
  padding: 1.5rem;
}

.payment-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.payment-main h4 {
  font-size: 1.1rem;
  color: white;
  margin: 0;
}

.payment-amount {
  font-size: 1.2rem;
  font-weight: 500;
  color: #6200ee;
}

.payment-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.payment-date {
  color: #b3b3b3;
  font-size: 0.9rem;
}

.payment-status {
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.payment-status.succeeded {
  background: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.payment-status.pending {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.payment-status.failed {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.receipt-link {
  display: inline-block;
  margin-top: 0.5rem;
  color: #6200ee;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.receipt-link:hover {
  color: #7722ff;
}

.no-payments {
  text-align: center;
  padding: 3rem;
  background: #252525;
  border-radius: 12px;
  color: #b3b3b3;
}

.plan-info {
  color: #b3b3b3;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.info-message {
  background: #560eb4;
  color: #b3b3b3;
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
}

.upgrade-btn {
  display: inline-block;
  background: #6200ee;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.upgrade-btn:hover {
  background: #7722ff;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .payment-main {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .payment-amount {
    font-size: 1rem;
  }
} 