.header {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  width: 90%;
  max-width: 1200px;
}

.header.scrolled {
  background: rgba(0, 0, 0, 0.9);
}

.header-container {
  width: 95.5%;
  padding: 0.75rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.5rem;
}

.logo img {
  height: 32px;
}

.logo span {
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}

.navigation ul {
  display: flex;
  gap: 2rem;
  list-style: none;
}

.navigation a {
  color: #fff;
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.navigation a:hover {
  color: #6FCF97;
}

.auth-buttons {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  transition: all 0.3s ease;
}

.login-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

.login-btn svg {
  width: 16px;
  height: 16px;
}

.try-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 1.25rem;
  background: #6200ee;
  color: #fff;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.try-btn:hover {
  background: #7722ff;
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .header {
    width: 95%;
    top: 0.5rem;
  }
  
  .header-container {
    padding: 0.75rem 1rem;
  }
  
  .navigation {
    display: none;
  }
  
  .auth-buttons {
    gap: 0.5rem;
  }
}
