.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  padding: 6rem 2rem;
  position: relative;
  overflow: hidden;
  background: #000000; /* Fundo preto sólido */
}

.hero-content {
  max-width: 600px;
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #fff;
}

.hero-title .highlight {
  background: linear-gradient(120deg, #6200ee, #9c27b0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #a0a0a0;
  margin-bottom: 2.5rem;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.cta-button {
  padding: 0.875rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background: #6200ee;
  color: #fff;
}

.cta-button.primary:hover {
  background: #7722ff;
  transform: translateY(-2px);
}

.cta-button.secondary {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  backdrop-filter: blur(10px);
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.hero-stats {
  display: flex;
  gap: 3rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.stat-number {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

.stat-label {
  font-size: 0.875rem;
  color: #a0a0a0;
}

.hero-visual {
  position: absolute;
  right: -25%;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  height: 120%;
  z-index: 1;
}

.gradient-sphere {
  position: absolute;
  width: 1000px;
  height: 1000px;
  right: -100px;
  top: 50%;
  transform: translateY(-50%);
}

/* Esfera principal roxa */
.gradient-sphere::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(98, 0, 238, 0.3) 0%,
    rgba(156, 39, 176, 0.2) 40%,
    rgba(98, 0, 238, 0.1) 60%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 50%;
  filter: blur(80px);
  animation: spherePulse 12s ease-in-out infinite;
}

/* Esfera verde */
.gradient-sphere::after {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  top: -30%;
  left: -20%;
  background: radial-gradient(
    circle,
    rgba(111, 207, 151, 0.25) 0%,
    rgba(98, 238, 167, 0.15) 50%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 50%;
  filter: blur(80px);
  animation: spherePulse2 15s ease-in-out infinite;
}

/* Esferas adicionais ajustadas */
.gradient-sphere .sphere-1 {
  width: 600px;
  height: 600px;
  top: 30%;
  right: -30%;
  background: radial-gradient(
    circle,
    rgba(98, 0, 238, 0.2) 0%,
    rgba(98, 0, 238, 0.1) 50%,
    rgba(0, 0, 0, 0) 80%
  );
  animation: sphereFloat 20s ease-in-out infinite;
}

.gradient-sphere .sphere-2 {
  width: 500px;
  height: 500px;
  bottom: -20%;
  right: 10%;
  background: radial-gradient(
    circle,
    rgba(111, 207, 151, 0.2) 0%,
    rgba(111, 207, 151, 0.1) 50%,
    rgba(0, 0, 0, 0) 80%
  );
  animation: sphereFloat2 18s ease-in-out infinite;
}

.gradient-sphere .sphere-3 {
  width: 400px;
  height: 400px;
  top: 20%;
  left: 0;
  background: radial-gradient(
    circle,
    rgba(156, 39, 176, 0.2) 0%,
    rgba(156, 39, 176, 0.1) 50%,
    rgba(0, 0, 0, 0) 80%
  );
  animation: sphereFloat3 22s ease-in-out infinite;
}

@keyframes spherePulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes spherePulse2 {
  0% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.9;
  }
  100% {
    transform: scale(1.1);
    opacity: 0.7;
  }
}

@keyframes sphereFloat {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-30px, -30px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes sphereFloat2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes sphereFloat3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(25px, 25px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media (max-width: 1024px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-visual {
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 4rem 1rem;
    text-align: center;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-cta {
    justify-content: center;
  }

  .hero-stats {
    justify-content: center;
  }

  .hero-visual {
    display: none;
  }
}

.hero-image {
  width: 100%;
  max-height: 900px;
  object-fit: cover;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.hero-columns {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 1.5rem;
}

.column {
  flex: 1;
  text-align: left; /* Texto alinhado à esquerda */
}

.column h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #6FCF97;
}

.column ul {
  list-style: none;
  padding: 0;
}

.column ul li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.column ul li strong {
  color: #6FCF97;
}

.features {
  display: flex;
  justify-content: center;
  gap: 3rem; /* Aumente o espaçamento horizontal entre as colunas */
  margin-top: 3rem; /* Espaçamento acima da seção */
}

.feature-item {
  text-align: center;
  max-width: 300px; /* Aumenta o tamanho máximo de cada item */
  margin: 0 5rem; /* Espaçamento lateral adicional */
}

.feature-icon {
  width: 60px; /* Aumente o tamanho do ícone */
  height: 60px;
  margin-bottom: 1.0rem; /* Espaçamento maior entre o ícone e o título */
}

.feature-item h3 {
  font-size: 1.6rem;
  color: #6FCF97; /* Cor do título */
  margin-bottom: 1rem; /* Espaçamento maior entre o título e o texto */
}

.feature-item p {
  font-size: 1rem;
  color: #fff; /* Texto branco */
  line-height: 1.5; /* Melhor espaçamento entre linhas */
}


