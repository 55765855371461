.user-settings {
  max-width: 900px;
  margin: 120px auto 40px; /* Aumentei o topo para empurrar mais para baixo */
  color: white;
  text-align: center;
}

.main-content-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  min-height: 80vh; /* Mantém o conteúdo mais centralizado na tela */
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #1a1a1a;
  padding: 30px; /* Aumentei o padding para melhor espaçamento */
  border-radius: 10px;
  width: 100%;
  max-width: 700px; /* Aumentei o tamanho do container */
}

.basic-info, .account-plan, .usage {
  background: #252525;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 94.4%;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #ddd;
}

.info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.avatar-preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.upload-btn {
  display: inline-block;
  background: #6200ee;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.upload-btn input {
  display: none;
}

.account-plan p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.upgrade-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.upgrade-buttons button {
  background-color: #ffcc00;
  color: black;
  border: none;
  padding: 10px 15px; /* Aumentei o padding dos botões */
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s;
}

.upgrade-buttons button:hover {
  background-color: #ffdb4d;
}

.usage-metrics p {
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: center;
}

.message {
  color: #4CAF50;
  text-align: center;
  margin-top: 10px;
}

.cancel-button {
  background-color: #ff5722;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;
}

.cancel-button:hover {
  background-color: #e64a19;
}

.delete-button {
  background-color: #d32f2f;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;
}

.delete-button:hover {
  background-color: #b71c1c;
}

.settings-page {
  min-height: 100vh;
  background: #000000;
  color: #fff;
}

.settings-content {
  padding: 7rem 2rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.settings-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
}

.settings-card h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #6200ee;
}

/* Profile Section */
.profile-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.avatar-section {
  display: flex;
  justify-content: center;
}

.avatar-container {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #6200ee;
  background-color: rgba(255, 255, 255, 0.1); /* Fundo para quando a imagem estiver carregando */
}

/* Novo overlay para o hover */
.avatar-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.avatar-upload-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #6200ee;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0; /* Inicialmente invisível */
  white-space: nowrap;
}

/* Mostrar o botão e o overlay no hover */
.avatar-container:hover::before,
.avatar-container:hover .avatar-upload-button {
  opacity: 1;
}

.avatar-container:hover .profile-avatar {
  filter: blur(1px);
}

.avatar-upload-button:hover {
  background: #7722ff;
  transform: translate(-50%, -50%) scale(1.05);
}

.avatar-upload-button input {
  display: none;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.info-item label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

/* Plan Section */
.plan-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.current-plan h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.plan-details {
  color: rgba(255, 255, 255, 0.7);
}

.upgrade-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upgrade-options button,
.plan-actions button {
  background: #6200ee;
  color: #fff;
  border: none;
  padding: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.upgrade-options button:hover,
.plan-actions button:hover {
  background: #7722ff;
  transform: translateY(-2px);
}

.upgrade-options button:disabled,
.plan-actions button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.upgrade-options button.loading,
.plan-actions button.loading {
  position: relative;
  cursor: not-allowed;
}

.upgrade-options button.loading::after,
.plan-actions button.loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  margin-left: 0.5rem;
}

.current-plan-btn {
  background: transparent !important;
  border: 1px solid #6200ee !important;
  color: #6200ee !important;
  cursor: default !important;
}

.current-plan-btn:hover {
  transform: none !important;
  background: transparent !important;
}

/* Mantendo a animação de spin existente */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .upgrade-options button,
  .plan-actions button {
    padding: 0.6rem;
    font-size: 0.85rem;
  }
}

/* Danger Zone */
.danger-zone {
  border-color: rgba(255, 68, 68, 0.3);
}

.danger-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cancel-subscription,
.delete-account {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ff4444;
  border-radius: 8px;
  background: transparent;
  color: #ff4444;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-subscription:hover,
.delete-account:hover {
  background: rgba(255, 68, 68, 0.1);
}

/* Message */
.message {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 2rem;
  border-radius: 8px;
  animation: slideUp 0.3s ease;
}

.message.success {
  background: rgba(98, 0, 238, 0.1);
  border: 1px solid rgba(98, 0, 238, 0.2);
  color: #6200ee;
}

.message.error {
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.2);
  color: #ff4444;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .settings-content {
    padding: 6rem 1rem 1rem;
  }

  .settings-grid {
    grid-template-columns: 1fr;
  }
}

.usage-stats {
  margin: 1.5rem 0;
}

.usage-item {
  margin-bottom: 1.5rem;
}

.usage-item h3 {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.usage-item p {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.usage-bar {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.usage-progress {
  height: 100%;
  background: #6200ee;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.usage-details {
  display: flex;
  gap: 1rem;
  margin: 0.5rem 0;
}

.usage-details small {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

/* Mantendo os estilos existentes dos botões */
.plan-actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.usage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.usage-numbers {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.usage-details {
  margin: 0.75rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.usage-detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  padding: 0.25rem 0;
}

.detail-label {
  color: rgba(255, 255, 255, 0.5);
}

.detail-number {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.usage-bar {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
  margin-top: 0.5rem;
}

.usage-progress {
  height: 100%;
  background: #6200ee;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.plan-actions button {
  position: relative;
  overflow: hidden;
}

.plan-actions button.loading {
  cursor: not-allowed;
  opacity: 0.7;
}

.plan-actions button.loading::after {
  content: '';
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    left: 100%;
  }
}

/* Reaproveitando os estilos do modal da página de Pricing */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  position: relative;
  border: 1px solid #6200ee;
}

.modal-content.business-inquiry {
  max-width: 600px;
  padding: 2.5rem;
}

.modal-description {
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.8);
}

.business-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
  padding: 0.75rem;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #6200ee;
  background: rgba(98, 0, 238, 0.1);
}

.form-group input:disabled,
.form-group textarea:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.modal-actions button {
  flex: 1;
  padding: 0.75rem;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.modal-actions button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-actions .primary-btn {
  background: #6200ee;
  color: white;
  border: none;
}

.modal-actions .secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-actions .primary-btn:hover:not(:disabled) {
  background: #7722ff;
}

.modal-actions .secondary-btn:hover:not(:disabled) {
  border-color: rgba(255, 255, 255, 0.4);
}

