.benefits-section {
  position: relative;
  padding: 8rem 2rem;
  overflow: hidden;
  background: #000000;
}

.benefits-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.benefits-header {
  text-align: center;
  margin-bottom: 4rem;
}

.benefits-title {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
}

.benefits-title .highlight {
  background: linear-gradient(120deg, #6200ee, #9c27b0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.benefits-subtitle {
  font-size: 1.25rem;
  color: #a0a0a0;
  max-width: 600px;
  margin: 0 auto;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.benefit-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.benefit-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 1.5rem;
  position: relative;
}

.benefit-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.benefit-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10px;
  left: 10px;
  background: rgba(98, 0, 238, 0.2);
  border-radius: 8px;
  filter: blur(10px);
  z-index: -1;
}

.benefit-card h3 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
}

.benefit-card p {
  color: #a0a0a0;
  line-height: 1.6;
}

.benefits-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.gradient-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 70% 0%,
    rgba(98, 0, 238, 0.15) 0%,
    rgba(0, 0, 0, 0) 70%
  );
}

.gradient-overlay::before {
  content: '';
  position: absolute;
  width: 800px;
  height: 800px;
  right: -200px;
  top: -200px;
  background: radial-gradient(
    circle,
    rgba(98, 0, 238, 0.15) 0%,
    rgba(156, 39, 176, 0.1) 40%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 50%;
  filter: blur(80px);
  animation: spherePulse 15s ease-in-out infinite;
}

.gradient-overlay::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  left: -100px;
  top: -100px;
  background: radial-gradient(
    circle,
    rgba(111, 207, 151, 0.15) 0%,
    rgba(98, 238, 167, 0.1) 40%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 50%;
  filter: blur(80px);
  animation: spherePulse2 18s ease-in-out infinite;
}

@media (max-width: 1024px) {
  .benefits-grid {
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .benefits-section {
    padding: 4rem 1rem;
  }

  .benefits-title {
    font-size: 2.5rem;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
  }

  .benefit-card {
    padding: 1.5rem;
  }
}

  