/* Estilos para a página de Runs do Projeto */
.project-runs {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  padding-top: 70px;
}

.main-content {
  margin: 0 auto;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  width: 100%;
}

/* Header da página */
.runs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.runs-count {
  color: #b3b3b3;
  font-size: 0.9rem;
}

/* Seção de filtros */
.filters-section {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  background: #252525;
  padding: 1rem;
  border-radius: 10px;
}

.search-box {
  flex: 1;
}

.search-box input,
.filter-box select {
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border: 1px solid #333;
  border-radius: 6px;
  background: #1a1a1a;
  color: white;
  transition: all 0.3s ease;
}

.search-box input:focus,
.filter-box select:focus {
  border-color: #6200ee;
  outline: none;
}

/* Grid de runs */
.runs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* Cards de run */
.run-card {
  background: #252525;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
  position: relative;
}

.run-card:hover {
  transform: translateY(-5px);
}

.run-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
}

.run-status {
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.run-status.in_progress {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.run-status.completed {
  background: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.run-status.failed {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.run-info {
  padding: 1.5rem;
}

.run-info h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: white;
}

.run-date {
  color: #b3b3b3;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.run-actions {
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  gap: 0.8rem;
  justify-content: center;
  border-top: 1px solid #333;
}

.dashboard-button,
.download-button {
  align-items: center;
  gap: 1.5rem;
  padding: 0.8rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0s ease;
}

.dashboard-button,
.download-button {
  background: linear-gradient(135deg, #8238eb 0%, #6200ee 100%);
  color: white;
  border: none;
}

.dashboard-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(130, 56, 235, 0.2);
}

.download-button:hover {
  background: #8438ff;
}

.dashboard-button svg,
.download-button svg {
  font-size: 1rem;
}

/* Botão de criar run */
.create-run-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #6200ee;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.create-run-btn:hover {
  background: #7722ff;
  transform: translateY(-2px);
}

.create-run-btn span {
  font-size: 1.2rem;
}

/* Estado vazio */
.no-runs {
  grid-column: 1 / -1;
  text-align: center;
  padding: 3rem;
  background: #252525;
  border-radius: 12px;
}

.no-runs p {
  margin-bottom: 1.5rem;
  color: #b3b3b3;
}

/* Paginação */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.page-btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  background: #252525;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.page-btn:hover:not(:disabled) {
  background: #333;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  color: #b3b3b3;
}

/* Responsividade */
@media (max-width: 768px) {
  .filters-section {
    flex-direction: column;
  }
  
  .runs-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

/* Adicionando apenas os novos estilos para os detalhes */
.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.detail-label {
  color: #b3b3b3;
  font-size: 0.9rem;
}

.detail-value {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
}
  