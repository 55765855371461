.plans-page {
  min-height: 100vh;
  padding: 4rem 2rem;
  background-color: #000000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plans-header {
  text-align: center;
  margin-bottom: 3rem;
  width: 100%;
  max-width: 1200px;
}

.plans-header h1 {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 5px #6FCF97; /* Sombra para destaque */
}

.plans-header p {
  color: #ffffff;
  font-size: 1.1rem;
}

.plans-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.plan-card {
  background: #2a2a2a;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  position: relative;
  transition: transform 0.3s ease;
  border: 1px solid #333;
  height: 100%;
  min-height: 520px;  /* Altura ajustada */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.plan-card.popular {
  border: 2px solid #007bff;
  background: #2d3748;
}

.popular-tag {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.plan-card h2 {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 1rem;
}

.price {
  margin-bottom: 2rem;
}

.price .amount {
  font-size: 2.5rem;
  font-weight: bold;
  color: #007bff;
  text-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
}

.price .period {
  color: #b3b3b3;
  font-size: 1.1rem;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  flex-grow: 1;
  display: flex;  /* Mudado para flex */
  flex-direction: column;  /* Organiza itens verticalmente */
  flex-wrap: wrap;  /* Permite quebra em colunas */
  max-height: 180px;  /* Altura máxima para forçar 3 itens por coluna */
  gap: 1rem;
  align-content: space-between;
}

.features li {
  display: flex;
  align-items: flex-start;
  color: #e0e0e0;
  font-size: 0.9rem;
  line-height: 1.4;
  width: 45%;  /* Largura para caber 2 colunas com espaço */
}

.check-icon {
  color: #00ff00;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  flex-shrink: 0;
  margin-top: 0.2rem;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.3);
}

.select-plan-button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #6200ee;;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: auto;
}

.select-plan-button:hover:not(:disabled) {
  background-color: #57a878;
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.4);
}

.select-plan-button:disabled {
  background-color: #4a4a4a;
  cursor: not-allowed;
}

.select-plan-button.loading {
  opacity: 0.7;
}

.error-message {
  text-align: center;
  color: #ff4444;
  margin-top: 2rem;
  padding: 1rem;
  background-color: rgba(255, 68, 68, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(255, 68, 68, 0.2);
}

.plans-footer {
  text-align: center;
  margin-top: 3rem;
  width: 100%;
  max-width: 1200px;
  color: #ffffff;
}

.plans-footer a {
  color: #6FCF97;
  text-decoration: none;
  transition: color 0.3s ease;
}

.plans-footer a:hover {
  text-decoration: underline;
}

.guarantee {
  color: #00ff00;
  margin-top: 1rem;
  font-size: 0.9rem;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.2);
}

.plan-card .upgrade-btn {
  width: 100%;
  padding: 0.8rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #6200ee;
  color: white;
  border: none;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.plan-card .upgrade-btn:hover {
  background: #7722ff;
  transform: translateY(-2px);
}

.plan-card .upgrade-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.plan-card .upgrade-btn.loading {
  position: relative;
  cursor: not-allowed;
}

.plan-card .upgrade-btn.loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  margin-left: 0.5rem;
}

.plan-card.current .upgrade-btn {
  background: transparent;
  border: 1px solid #6200ee;
  color: #6200ee;
  cursor: default;
}

.plan-card.current .upgrade-btn:hover {
  transform: none;
  background: transparent;
}

.plan-card .contact-btn {
  width: 100%;
  padding: 0.8rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: white;
  border: 1px solid #333;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.plan-card .contact-btn:hover {
  border-color: #6200ee;
  color: #6200ee;
  transform: translateY(-2px);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1240px) {
  .plans-container {
    max-width: 90%;
    gap: 1.5rem;
  }
}

@media (max-width: 1024px) {
  .features {
    max-height: none;  /* Remove altura máxima */
    flex-direction: column;
    flex-wrap: nowrap;
  }
  
  .features li {
    width: 100%;  /* Ocupa largura total */
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 768px) {
  .plans-container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
    max-width: 100%;
  }

  .plans-page {
    padding: 2rem 1rem;
  }

  .plans-header h1 {
    font-size: 2rem;
  }

  .plan-card {
    min-height: auto;
  }

  .price .amount {
    font-size: 2rem;
  }

  .plan-card .upgrade-btn,
  .plan-card .contact-btn {
    padding: 0.6rem;
    font-size: 0.85rem;
  }
} 